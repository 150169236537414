/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.3 pc.gltf
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/pc.gltf')
  const scale = 0.01
  return (
    <group {...props} dispose={null}>
      <group position={[-0.07, -0.81, -0.44]} rotation={[-Math.PI / 2, 0, 0]} scale={scale}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0, -5.37, -41.87]} rotation={[-Math.PI / 2, 0, 0]} scale={120.57}>
            <mesh geometry={nodes.Plane_BackgroundMaterial_0.geometry} material={materials.BackgroundMaterial} />
          </group>
          <mesh 
            geometry={nodes.Comp_Screen000_TerminalMaterial_0.geometry} 
            material={materials.TerminalMaterial} 
            position={[18.16, 0, 0]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={212.25}
            onClick={(e) => props.zoomToView({x: -0.42, y: 0.9, z: 0.7})}
          />
          <mesh 
            geometry={nodes.Comp_Screen002_TerminalMaterial_0.geometry} 
            material={materials.TerminalMaterial} 
            position={[18.16, 0, 0]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={212.25}
            onClick={(e) => props.zoomToView({x: -0.42, y: 0.9, z: 0.7})}
          />
        </group>
      </group>
      {/* Who */}
      <group position={[-0.07, -0.81, -0.44]} rotation={[-Math.PI / 2, 0, 0]} scale={scale}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh 
            geometry={nodes.Plane_BackgroundMaterial_0005.geometry} 
            material={materials['BackgroundMaterial.005']} 
            position={[0, -5.37, -41.87]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={120.57} 
            onClick={(e) => props.zoomToView({x: -4.5, y: 0, z: 2.2})}
          />
        </group>
      </group>
      {/* Email */}
      <group position={[-0.07, -0.81, -0.44]} rotation={[-Math.PI / 2, 0, 0]} scale={scale}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh 
            geometry={nodes.Plane_BackgroundMaterial_0001.geometry} 
            material={materials['BackgroundMaterial.001']} 
            position={[0, -5.37, -41.87]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={120.57} 
            onClick={() => (props.zoom ? window.open('mailto:vincentlewii@gmail.com', '_blank') : props.zoomToView({x: -0.42, y: 0.9, z: 0.7}))}
          />
        </group>
      </group>
      {/* Github */}
      <group position={[-0.07, -0.81, -0.44]} rotation={[-Math.PI / 2, 0, 0]} scale={scale}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh 
            geometry={nodes.Plane_BackgroundMaterial_0004.geometry} 
            material={materials['BackgroundMaterial.004']} 
            position={[0, -5.37, -41.87]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={120.57} 
            onClick={() => (props.zoom ? window.open('https://www.github.com/vincentlewi', '_blank') : props.zoomToView({x: -0.42, y: 0.9, z: 0.7}))}
          />
        </group>
      </group>
      {/* Instagram */}
      <group position={[-0.07, -0.81, -0.44]} rotation={[-Math.PI / 2, 0, 0]} scale={scale}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh 
            geometry={nodes.Plane_BackgroundMaterial_0003.geometry} 
            material={materials['BackgroundMaterial.003']} 
            position={[0, -5.37, -41.87]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={120.57} 
            onClick={() => (props.zoom ? window.open('https://www.instagram.com/vincentlewi', '_blank') : props.zoomToView({x: -0.42, y: 0.9, z: 0.7}))}
          />
        </group>
      </group>
      {/* LinkedIn */}
      <group position={[-0.07, -0.81, -0.44]} rotation={[-Math.PI / 2, 0, 0]} scale={scale}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh 
            geometry={nodes.Plane_BackgroundMaterial_0002.geometry} 
            material={materials['BackgroundMaterial.002']} 
            position={[0, -5.37, -41.87]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={120.57} 
            onClick={() => (props.zoom ? window.open('https://www.linkedin.com/in/lewivincent', '_blank') : props.zoomToView({x: -0.42, y: 0.9, z: 0.7}))}
          />
        </group>
      </group>
      {/* Projects */}
      <group position={[-0.07, -0.81, -0.44]} rotation={[-Math.PI / 2, 0, 0]} scale={scale}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh 
            geometry={nodes.Plane_BackgroundMaterial_0007.geometry} 
            material={materials['BackgroundMaterial.010']} 
            position={[0, -5.37, -41.87]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={120.57} 
            onClick={(e) => props.zoomToView({x: 5, y: -0.5, z: 3.2})}
          />
        </group>
      </group>
      {/* Resume */}
      <group position={[-0.07, -0.81, -0.44]} rotation={[-Math.PI / 2, 0, 0]} scale={scale}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh 
            geometry={nodes.Plane_BackgroundMaterial_0008.geometry} 
            material={materials['BackgroundMaterial.008']} 
            position={[0, -5.37, -41.87]} 
            rotation={[-Math.PI / 2, 0, 0]} 
            scale={120.57} 
            onClick={(e) => (props.zoom ? window.open('/Resume Alexander Vincent Lewi.pdf', '_blank') : props.zoomToView({x: -0.42, y: 0.9, z: 0.7}))}
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/pc.gltf')
